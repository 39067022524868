a[data-tooltip] {
  font-size: inherit !important;
  color: black !important;
  font-weight: inherit !important;
  text-decoration: underline !important;
  position: relative;
}

.ant-tooltip-inner {
  background-color: var(--primary-background);
  color: var(--primary);
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: var(--primary-background);
}
