@import "../variables";

.c-form-control-container {
  display: flex;
  margin-bottom: 10px;
}

.c-form-group {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2px;
}

.c-input, .c-select {
  height: 56px;
  line-height: 1;
  border-color: var(--text-light);
  color: var(--text-default);
}

.c-select {
  display: flex !important;
  align-items: center !important;
  padding-left: 0;
  background-color: #e9ecef !important;
}


.required-star {
  color: #e55039;
}

/*
 * ----------------------------------------
 * Inputs
 * ----------------------------------------
 */

input.form-control:focus,
textarea:focus,
textarea.form-control:focus,
.c-select:focus,
.form-control:focus {
  color: var(--text-default);
  border: 2px solid var(--text-light) !important;
  box-shadow: none !important;
}

input::placeholder {
  color: var(--text-default);
}

label,
.c-label {
  color: var(--text-default);
}

.c-entry {
  flex-grow: 1;
  font-size: map_get($font-size, default);
  letter-spacing: 0.3px;
  line-height: 1.2;
  color: var(--default);
  position: relative;
  margin-bottom: 0 !important;

  label {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
    position: absolute;
    top: -17px;
    line-height: 1;
    color: var(--text-light);
  }
}

.c-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  min-width: 45px;
  margin-right: map_get($space, middle);
  color: var(--icon-color);
  fill: var(--icon-color);
  margin-top: 4px;

  &.c-alight-top {
    margin-top: 56px;
    align-self: flex-start;
  }
}

.c-info-icon {
  margin-left: 10px;
  fill: var(--icon-color)
}
