button[disabled] {
  cursor: not-allowed;
}

.btn-primary {
  background-color: var(--primary-background);
  color: var(--primary);
  border: var(--primary-background);

  &:not(:disabled):not(.disabled) {
    &:active, &:focus {
      background-color: var(--primary-background);
      color: var(--primary);
      border: var(--primary-background);
    }
  }
}

.btn-primary:hover {
  background-color: var(--primary-background);
  color: var(--primary);
  border: var(--primary-background);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary-background);
  border: var(--primary);
  opacity: 0.65;
}

.btn-impressum {
  color: #000;

  &:hover {
    color: #000;
  }

  &:visited {
    color: var(--text-light);
  }
}

.g-btn-bold {
  font-size: 15px;
  letter-spacing: 0.7px;
  padding: 15px;
  font-weight: 500;
}

.c-btn-send {
  width: 100%;
  max-width: 406px;
  min-height: 55px;
}
