/*
* ----------------------------------------
* Bootstrap
* ----------------------------------------
*/

// Core bootstrap file
@import "bootstrap/scss/functions";

// Original variables
@import "bootstrap/scss/variables";

// Another core bootstrap file
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/reboot";

// Core styles
@import "bootstrap/scss/print";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities";

// Used components
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/forms";

/*
 * ----------------------------------------
 * Custom components
 * ----------------------------------------
 */
@import "scss/variables";

@import "./scss/theme";
@import "./scss/body";
@import "./scss/layout";
@import "./scss/components/button";
@import "./scss/components/lock-loader";
@import "./scss/components/form";
@import "./scss/components/select";
@import "./scss/components/checkbox";
@import "./scss/components/alert";
@import "./scss/components/tooltip";


