$pretty--box-size: calc(1.5em + 2px);

@import 'pretty-checkbox/src/pretty-checkbox.scss';

.pretty.p-pulse:not(.p-switch) {
  input:checked~.state {
    label:before {
      animation: pulse 0.5s !important;
    }
  }
}

.pretty input {
  height: 1.8em !important;
}

.pretty.p-svg .state .svg {
  width: 1.3em !important;
  height: 1.3em !important;
  top: -5px !important;
}

.custom-checkbox .state.p-primary label:after {
  background-color: var(--primary);
  border: 1px solid var(--primary-background);
}

.custom-checkbox input:checked~.state.p-primary label:after,
.custom-checkbox.p-toggle input:checked.state.p-primary label:after {
  /* background-color: #4f4394 !important; */
  background: var(--primary-background) !important;
  border: 1px solid var(--primary-background);
}
