html {
  scroll-behavior: smooth;
}

body {
	background: var(--page-background);
	-webkit-font-smoothing: subpixel-antialiased;
	//-moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
