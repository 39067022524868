:root {
  --text-default: #444444;
  --text-light: #B3BBC7;
  --primary: #fff;
  --primary-background: #4F4394;
  --secondary: #4F4394;
  --secondary-background: #18324c10;
  --box: #4F4394;
  --box-background: #18324c10;
  --icon-color: #4F4394;
  --warn: #000;
  --warn-background: #fffee1;
  --error: #000;
  --error-background: #ffe1e1;
  --page-background: #fff;
  --form-background: #fff;
}
