@import "bootstrap/scss/mixins/breakpoints";
@import "./variables";

.c-app-content {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--form-background);
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    width: 576px !important;
    min-height: calc(100vh - #{map_get($space, middle)*2}) !important;
    margin: map_get($space, middle) 0 !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: map_get($space, xs);
    overflow: hidden;
  }
}

.small-container {
  max-width: 500px;
  margin: map_get($space, middle) auto;
  display: block;
  text-align: center;
  overflow: hidden;
}

/* ----------------------------------------
 * Group
 * -------------------------------------- */

.c-category {
  display: flex;
  flex-direction: column;
  position: relative;
}

.c-person-icon {
  fill: var(--icon-color);
}

.c-category-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  min-width: 85px;
  fill: var(--secondary);
}

.c-category-title {
  flex-grow: 1;
  letter-spacing: 0.5px;
  font-size: 17px;
}

.c-category-content {
  overflow: hidden;
}

.c-title {
  color: var(--text-default);
}

.c-container {
  padding: map_get($space, middle) map_get($space, middle) map_get($space, middle);
}

.c-button-container {
  padding: map_get($space, small) map_get($space, middle) map_get($space, small);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.c-box-container {
  background-color: var(--box-background);
  color: var(--box);
}
