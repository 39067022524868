.message-box {
  margin: 20px;
  padding: 10px;
  border-radius: 10px;

  p {
    font-size: 15px;
  }

  &.error {
    background: var(--error-background);
    color: var(--error);
  }

  &.warn {
    background: var(--warn-background);
    color: var(--warn);
  }
}
