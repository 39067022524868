nz-select-top-control {
  height: 33px !important;
  border: none !important;
  padding-top: 3px !important;
  box-shadow: none !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #e9ecef !important;

}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding-left: 16px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 16px;
}
