.lds-dual-ring {
  display: inline-block;
  width: 140px;
  height: 140px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 124px;
  height: 124px;
  margin: 8px;
  border-radius: 50%;
  border: 5px solid var(--primary-background);
  border-color: var(--primary-background) transparent var(--primary-background) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lock-container {
  margin-left: 54px;
  margin-top: 48px;
  position: absolute;
}

.lock {
  height: 30px;
  width: 30px;
  background-color: var(--primary-background);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shackle {
  width: map_get($space, middle);
  height: map_get($space, middle);
  border-radius: 50%;
  transform: rotate(-45deg);
  margin-left: 5px;
  margin-bottom: -10px;
  border: 5px solid var(--primary-background);
  border-color: var(--primary-background) var(--primary-background) transparent transparent;
}

.key-hole {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #ffffff;
}
