@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@theme: default;

// The prefix to use on all css classes from ant.
@ant-prefix: ant;

// An override for the html selector for theme prefixes
@html-selector: html;

// -------- Colors -----------
//@primary-color: @blue-6;
@primary-color: #4f4394;
@info-color: @blue-6;

//@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
//'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
//'Noto Color Emoji';
//@font-family: Roboto, "Roboto", Helvetica, sans-serif;
@font-family: Rubik, "Rubik", Helvetica, sans-serif;
@text-color: fade(@black, 65%);
@heading-color-dark: fade(@white, 100%);

// https://github.com/ant-design/ant-design/issues/20210
@line-height-base: 1.4;
@border-radius-base: 4px;

// vertical paddings
@padding-lg: 30px; // containers
@padding-md: 20px; // small containers and buttons
@padding-sm: 10px; // Form controls and items
@padding-xs: 5px; // small items
@padding-xss: 3px; // more small

// vertical margins
@margin-lg: 30px; // containers
@margin-md: 20px; // small containers and buttons
@margin-sm: 10px; // Form controls and items
@margin-xs: 5px; // small items
@margin-xss: 3px; // more small

// height rules
@height-base: 30px;
@height-lg: 38px;
@height-sm: 25px;

// Buttons
@btn-link-ghost-color: @component-background;

// Radio buttons
@radio-disabled-button-checked-color: @text-color-inverse;

// Grid system
@grid-gutter-width: 0;

// Layout
//@zindex-table-fixed: auto;
@zindex-table-fixed: 2;
@zindex-tooltip: 1060;

// Modal
// --
@modal-header-padding: @padding-md @padding-lg;

// Menu
@menu-icon-size: @font-size-base;
@menu-icon-size-lg: @font-size-lg;


// Table
// --
@table-row-hover-bg: @item-hover-bg;
@table-selected-row-hover-bg: @table-selected-row-bg;

// Card
// ---
@card-head-padding-sm: @card-head-padding / 2;
@card-padding-base-sm: @card-padding-base / 2;
@card-actions-background: @background-color-light;

// Switch
// ---
@switch-sm-checked-margin-left: -(@switch-sm-height - 3px);

// Pagination
// ---
@pagination-font-family: Arial;

// Skeleton
// ---
@skeleton-color: #f2f2f2;


// Statistic
// ---
@statistic-unit-font-size: 16px;

